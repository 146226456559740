* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: lightgrey;
  padding: 60px;
}

input {
  margin-top: 24px;
}

ul, ol {
  text-align: left;
}

li {
  list-style-position: inside;
  margin-left: 30px;
}

li + li {
  margin-top: 8px;
}

li a + a {
  margin-left: 8px;
  text-decoration: none;
}

section header {
  width: 100%;
  margin: 0 0 30px 0;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

h3 {
  font-weight: normal;
}

section {
  background: white;
  padding: 30px;
  margin: 0 0 80px 0;
}

main {
  display: grid;
  grid-template-columns: 2fr 6fr;
  padding: 80px 0 0 0;
  gap: 30px;
}

.nav {
  position: sticky;
  top: 0;
  height: 99vh;
  overflow-y: scroll;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav li span {
  display: inline-block;
  padding: 4px;
  font-size: 11px;
  background: #f5f5f5;
  margin-left: 8px;
}

.nav li span.danger {
  background: #d50000;
  color: white;
}

.instructions {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: white;
}